import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import FamilyMembers from "./pages/FamilyMembers";
import LoginForm from "./pages/LoginForm";
import { ToastContainer, toast } from "react-toastify";
import { ApolloAuthProvider } from "./components/ApolloAuthProvider";
import "react-toastify/dist/ReactToastify.css";
import { MainLayout } from "./components/MainLayout";
import HomePage from "./pages/HomePage";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );

  const [userRole, setUserRole] = useState(!!localStorage.getItem("userRole"));

  useEffect(() => {
    // Check authentication status and username on component mount
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);

    const userRole = localStorage.getItem("userRole") || "";
    if (userRole) {
      setUserRole(userRole);
    } else {
      setUserRole("Public");
    }

    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    setIsAuthenticated(false);
    setUsername("");
    toast.success("Logged out successfully");
  };

  return (
    <>
      <Router>
        <ApolloAuthProvider>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Navigate to={isAuthenticated ? "/family-members" : "/login"} />
              }
            />
            <Route
              exact
              path="/home"
              element={<HomePage userRole={userRole} />}
            />
            <Route
              path="/login"
              element={
                <LoginForm
                  setIsAuthenticated={setIsAuthenticated}
                  setUsername={setUsername} // Pass setUsername to LoginForm
                />
              }
            />
            <Route
              path="/family-members"
              element={
                isAuthenticated ? (
                  <MainLayout
                    handleLogout={handleLogout}
                    isAuthenticated={isAuthenticated}
                    username={username}
                  >
                    <FamilyMembers
                      isAuthenticated={isAuthenticated}
                      username={username}
                      userRole={userRole}
                    />
                  </MainLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Routes>
        </ApolloAuthProvider>
      </Router>
      <ToastContainer autoClose={3000} />
    </>
  );
};

export default App;
