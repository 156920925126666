import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)

const DeleteConfirmationModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  message = "Are you sure you want to proceed?", // Default message
  title = "Confirm Action",
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Delete Confirmation"
      className="modal flex flex-col justify-center items-center bg-white rounded-lg shadow-lg p-6 max-w-sm mx-auto"
      overlayClassName="overlay fixed inset-0 flex items-center justify-center"
    >
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <p className="mb-6">{message}</p>
      <div className="flex justify-between w-full">
        <button
          onClick={onConfirm}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200 ease-in-out"
        >
          Confirm
        </button>
        <button
          onClick={onRequestClose}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition duration-200 ease-in-out"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
