import React from "react";
import Header from "./Header";

export function MainLayout({
  handleLogout,
  username,
  children,
  isAuthenticated,
}) {
  return (
    <div>
      <Header
        handleLogout={handleLogout}
        isAuthenticated={isAuthenticated}
        username={username}
      />
      <main>{children}</main>
    </div>
  );
}
