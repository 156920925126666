import React, { useRef } from "react";
import { useQuery, gql } from "@apollo/client";
import ReactToPrint from "react-to-print";
import { FaPrint } from "react-icons/fa6";
import QRCode from "qrcode.react";
import "./Certificate.css";
import certLogo from "../images/cert-logo.png";
import signatureImg from "../images/signature.png";

const GET_FAMILY_LINEAGE = gql`
  query GetFamilyLineage($id: ID!) {
    familyMember(id: $id) {
      data {
        id
        attributes {
          name
          email
          address
          mobile
          date_of_birth
          place_of_birth
          gender
          is_alive
          nationality
          county
          clan
          mothers_clan
          village
          branch
          posterity
          thigh
          occupation
          date_of_death
          biographical_information
          children {
            data {
              id
              attributes {
                name
              }
            }
          }
          parent {
            data {
              id
              attributes {
                name
                email
                address
                mobile
                date_of_birth
                place_of_birth
                gender
                is_alive
                nationality
                county
                clan
                mothers_clan
                village
                branch
                posterity
                thigh
                occupation
                date_of_death
                biographical_information
                parent {
                  data {
                    id
                    attributes {
                      name
                      parent {
                        data {
                          id
                          attributes {
                            name
                            parent {
                              data {
                                id
                                attributes {
                                  name
                                  parent {
                                    data {
                                      id
                                      attributes {
                                        name
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Certificate = ({ memberId }) => {
  const { loading, error, data } = useQuery(GET_FAMILY_LINEAGE, {
    variables: { id: memberId },
  });

  const certificateRef = useRef();

  // Recursively collects the lineage of the member until there's no parent
  const collectLineage = (member, lineage = []) => {
    if (!member) return lineage;
    lineage.push(member.attributes.name);
    return collectLineage(member.attributes.parent?.data, lineage);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const familyMember = data?.familyMember?.data;
  if (!familyMember) {
    return <p>No family member data found.</p>;
  }

  // Get the full lineage of the selected member and reverse it to display in correct order
  const lineage = collectLineage(familyMember).reverse();
  const memberData = familyMember.attributes;
  const numberOfChildren = memberData.children?.data.length || 0;

  // Generate QR Code data string (you can customize this)
  const qrCodeData = `ALBAALAWY FAMILY CERTIFICATE: NAME: -- ${memberData.name} --, EMAIL: -- ${memberData.email} --, DATE OF BIRTH: ${memberData.date_of_birth}, CERTIFIED BY: Albaalawy, VERIFIED BY: `;

  return (
    <div className="">
      <ReactToPrint
        trigger={() => (
          <button
            variant="secondary"
            className="flex gap-2 mb-5 items-center text-xs md:text-sm py-2 px-5 border rounded-lg hover:bg-indigo-50"
          >
            <FaPrint
              title="print certificate"
              className="size-2 md:size-4 mr-2 cursor-pointer"
            />
            Print Certificate
          </button>
        )}
        content={() => certificateRef.current}
        documentTitle="Family Chain Certificate"
      />
      <div ref={certificateRef} className="certificate">
        <div className="certificate-wrapper">
          <div className="certificate-header">
            <img
              src={certLogo}
              className="cert-logo w-20 lg:w-[148px] m-auto"
              alt="cert-logo"
            />
            <h1 className="certificate-title text-xl lg:text-2xl xl:text-5xl text-center font-playwrite">
              Family Chain Certificate
            </h1>
            <h4 className="cert-subtitle text-xs lg:text-sm xl:text-xl font-graduate text-center mb-5">
              هذا ما تم إثباته بالمصادر المعتمدة في علم الأنساب بخصوص نسب{" "}
              <strong>{familyMember.attributes.name}</strong>، والذي تمتد سلسلته
              كما يلي من الشجرة الأم التي جمعها النسابة الحبيب علي زين العابدين
              بن عبدالله العيدروس.
            </h4>
          </div>
          <div className="certificate-content">
            <div className="lineage flex flex-wrap justify-center font-times mb-10">
              {lineage.map((name, index) => (
                <div className="lineage-item flex items-center" key={index}>
                  <p className="lineage-name text-xs lg:text-sm bg-lime-400 mr-2 border-2 rounded-full p-2">
                    {name}
                  </p>
                  {index < lineage.length - 1 && (
                    <p className="mr-2">
                      <span className="indexNumber bg-slate-500 text-white px-4 py-0">
                        {index + 1}
                      </span>
                    </p>
                  )}
                </div>
              ))}
            </div>

            {/* Bio Details Table */}
            <div className="bio-details w-[90%] mx-auto print:w-full overflow-x-hidden">
              <h4 className="lineage-table-title font-graduate text-xl lg:text-2xl text-center mb-3">
                البيانات العلويني – DATA BAALAWY
              </h4>
              <table className="bio-table w-full border-collapse overflow-x-scroll border border-black text-xs lg:text-sm">
                <tbody>
                  <tr>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      <p>
                        <small>(Mothers Clan) - </small>
                        قبيلة األم
                      </p>
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.mothers_clan || "N/A"}
                    </td>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Nationality
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.nationality || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Clan
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.clan || "N/A"}
                    </td>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Entry No.
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      RBLW-000{memberId || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      No. Of Children
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {numberOfChildren || "N/A"}
                    </td>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      County
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.county || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Branch
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.branch || "N/A"}
                    </td>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Email
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.email || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Date Of Birth
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.date_of_birth || "N/A"}
                    </td>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Village
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.village || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Address
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.address || "N/A"}
                    </td>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Mobile
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.mobile || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Posterity
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.posterity || "N/A"}
                    </td>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Thigh
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.thigh || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Place Of Birth
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.place_of_birth || "N/A"}
                    </td>
                    <td
                      className="p-2 border bg-lime-200 border-black"
                      colSpan={2}
                    >
                      Occupation
                    </td>
                    <td className="p-2 border border-black" colSpan={2}>
                      {memberData.occupation || "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="certificate-footer flex justify-between">
            <div className="w-1/2 flex flex-col gap-5 justify-center items-center border-2 border-dashed border-lime-950 rounded-full py-2 px-5">
              <p className="text-lime-950 relative">
                {" "}
                <img className="signature" src={signatureImg} alt="signature" />
                _________________ :تم التوقيع بواسطة
              </p>
              <p className="text-lime-950">
                <span className="underline underline-offset-4">
                  {new Date().toLocaleDateString()}
                </span>{" "}
                :تاريخ الإصدار
              </p>
            </div>
            <div className="w-1/4 qr-code-container flex flex-col items-center">
              <QRCode value={qrCodeData} className="qr-code w-[80px] h-auto" />
              <p className="text-center mb-0 text-sm qr-text">
                Certified by ALBAALAWY
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
