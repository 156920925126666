import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import logoImg from "../images/baalawy-logo.png";
import ConfirmationModal from "./DeleteConfirmationModal";

const Header = ({ handleLogout, isAuthenticated, username }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    handleLogout();
    navigate("/login");
  };

  const openDeleteModal = () => {
    setIsModalOpen(true);
  };

  return (
    <header className="bg-indigo-100  py-2 mb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto px-4 lg:px-0 lg:w-4/5">
        <h1 className="text-xl font-normal">
          <Link
            className="hover:no-underline active:text-gray-100 active:no-underline flex justify-center gap-2 items-center "
            to="/family-members"
          >
            {logoImg ? (
              <>
                <img className="w-8 h-8" src={logoImg} alt="logo" />
                <span>شجرة السادة آل باعلوي - شرق إفريقيا</span>
              </>
            ) : (
              <span className="hidden lg:block text-lg font-semibold">
                Family.io
              </span>
            )}
          </Link>
        </h1>

        {isAuthenticated && (
          <div className="text-center font-semibold text-sm xl:text-sm md:text-left flex justify-between items-center">
            <p className="mr-2">{username}</p>
            <IoLogOutOutline
              title="Logout"
              onClick={openDeleteModal}
              className="size-5 cursor-pointer"
            />
          </div>
        )}
        <ConfirmationModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onConfirm={handleLogoutClick}
          message="Are you sure you want to Logout?"
          title="Confirm Logout"
        />
      </div>
    </header>
  );
};

export default Header;
