import React from "react";
import Header from "../components/Header";

function HomePage({ userRole }) {
  return (
    <div>
      <Header userRole={userRole} />
      {userRole === "Authenticated" && (
        <p>Welcome to the Landing page {userRole}</p>
      )}
    </div>
  );
}

export default HomePage;
